/************ Custom Font ************/
@font-face {
	font-family: WorkSans;
	src: url('./assets/font/WorkSans-ExtraBold.ttf');
	font-weight: 800;
}
@font-face {
	font-family: WorkSans;
	src: url('./assets/font/WorkSans-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: WorkSans;
	src: url('./assets/font/WorkSans-SemiBold.ttf');
	font-weight: 600;
}
@font-face {
  font-family: Inter;
  src: url('./assets/font/Inter-SemiBold.ttf');
  font-weight: 600;
}
@font-face {
	font-family: WorkSans;
	src: url('./assets/font/WorkSans-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: WorkSans;
	src: url('./assets/font/WorkSans-Regular.ttf');
	font-weight: 400;
}
@font-face {
	font-family: SpaceGrotesk;
	src: url('./assets/font/SpaceGrotesk-Regular.ttf');
	font-weight: 400;
}
@font-face {
  font-family: Inter;
  src: url('./assets/font/Inter-Regular.ttf');
  font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-family: WorkSans;
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #FFFFFF !important;
  color: #08133B !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}

/************ Navbar Css ************/
@font-face {
  font-family: 'SIFONN';
  src: url('/src/assets/font/SIFONN.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

nav.navbar {
  padding: 10px 20px; 
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}

nav.navbar .navbar-nav {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0; 
}

span.navbar-text {
  display: flex;
  align-items: center;
  margin-left: auto; 
}

nav.navbar.scrolled {
  background-color: #FFFFFF;
}

nav.navbar a.navbar-brand {
  width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-family: 'SIFONN', sans-serif;
  font-weight: 500;
  color: #08133B !important;
  letter-spacing: 0.8px;
  padding: 0 15px; /* Reduced padding for cleaner look */
  font-size: 16px;
  opacity: 0.7;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
  font-weight: 600;
  font-family: 'SIFONN', sans-serif;
}
.social-links {
  display: flex;
  align-items: center;
}

.social-links a {
  margin-left: 10px; /* Consistent spacing between social icons */
}

.social-icon {
  width: 24px;
  height: 24px;
}

span.navbar-text {
    display: flex;
    align-items: center;
}

.account-icon {
    display: inline-block;
    margin-left: 14px;
}
.account-icon a {
    width: 38px;
    height: 38px;
    display: inline-flex;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
}

.account-icon a img {
	width: 52%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
nav.navbar {
  padding: 10px 20px; /* Adjust top and bottom padding */
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
  background-color: white; /* Ensure navbar has a background */
}

nav.navbar .navbar-nav {
  display: flex;
  align-items: center; /* Center vertically */
  margin: 0; /* Reset margin */
}

.router-text a {
  font-family: 'SIFONN', sans-serif; /* Ensure font is applied */
  font-weight: 600;
  color: #08133B !important;
  letter-spacing: 0.8px;
  padding: 10px 15px; /* Adjust padding for better vertical alignment */
  font-size: 18px;
  opacity: 0.55;
  text-decoration: none !important;
  margin-right: 20px; /* Add space between buttons */
  background: none; /* Remove background */
  border: none; /* Remove border */
}

.router-text a:last-child {
  margin-right: 0; /* Remove margin from the last button */
}

.router-text a:hover {
  opacity: 1;
}

.get-app-button {
  display: inline-block; /* Prevent text wrapping */
  white-space: nowrap; /* Ensure text stays on one line */
  padding: 10px 20px; /* Button padding */
  background-color: #08133B; /* Button background color */
  color: white; /* Button text color */
  border-radius: 5px; /* Rounded corners */
  text-align: center; /* Center the text */
  text-decoration: none; /* Prevent underline */
}



/* .navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
} */
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #08133B;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #08133B;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #08133B;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

  
/*******search bar*******/
.search {
  margin: 20px;
}

.search > h3 {
  font-weight: normal;
}

.search > h1,
.search > h3 {
  color: white;
  margin-bottom: 15px;
  text-shadow: 0 1px #0091c2;
}

.search > div {
  display: inline-block;
  position: relative;
  /* filter: drop-shadow(0 1px #0091c2); */
}

.search > div:after {
  content: "";
  background: #08133B;
  width: 2px;
  height: 7px;
  position: absolute;
  top: 18.5px;
  right: 0px;
  transform: rotate(135deg);
}

.search > div > input {
  color: #08133B;
  font-size: 16px;
  background: transparent;
  width: 8px;
  height: 8px;
  padding: 9px;
  border: solid 2px #08133B;
  outline: none;
  border-radius: 20px;
  transition: width 0.5s;
}

.search > div > input::placeholder {
  color: lightgrey;
  opacity: 0;
  transition: opacity 150ms ease-out;
}

.search > div > input:focus::placeholder {
  opacity: 1;
}

.search > div > input:focus,
.search > div > input:not(:placeholder-shown) {
  width: 200px;
}


/*********************BANNER CSS********************/
.banner {
  margin-top: 80px; 
}
.marquee-container {
  background-color: #FFFFFF;
  margin: 130px auto;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
}
.banner-container {
  /* background-color: red; */
  margin:130px auto;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:auto
}
@media screen and (max-width: 1220px){
  .mobile-container{
    display: block !important;
  }
  .mobile-elements{
    display: block !important;
  }
  .banner-container{
     flex-direction: column;
  }
  .banner-picture-part{
    order: 3;
  }
  .banner-text{
    float: none;
    order: 4;
  }
  .banner-title{
    display: none;
  }
  .banner-title-mobile{
    display: block !important;
    order: 1;
  }
  .social-icons-section{
    order: 2;
  }
  .scroll-down-arrow{
    display: none;
  }
  .social-icons-section {
    flex-direction: row !important;
    display: none !important;
  }
  .get-invited-button {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 850px){
  /* .banner-image{ 
    width: 393px !important;
    height: 300px !important;
  } */
  .banner-container{
    margin: 70px auto;
  }
  .banner-image{
    width: 340px !important;
    height: 247px !important;
  }
  .mobile-elements{
    width: 340px;
    align-items:center !important;

  }
  .banner-title-mobile h1{
    text-shadow:#F7F7FA 20px -30px !important;
  }
  .get-invited-button {
    font-size: 20px !important;
    padding: 9px 15px !important;
  }
   
}

.scroll-down-arrow{
  color: #08133B;
  font-family: WorkSans;
  writing-mode: vertical-lr;
  margin: 40px
}
.scroll-down-arrow h3{
  font-size: 16px;
  font-weight: 400;
  float: left;

}
.banner-text{
  font-family: WorkSans;
  color: #08133B;
  max-width: 550px;
  margin:40px
  /* float: left; */

}
.banner-title h1{
  font-weight: 600;
  font-family: Inter;
  font-size: 85px;
  text-shadow:#F7F7FA 60px -70px;
  letter-spacing: -8px;
}
.mobile-container{
  display: none;
}
.mobile-elements{
  display: flex !important;
  flex-direction: row-reverse;
  align-items:flex-end;
}
.banner-title-mobile{
  display: none;
  /* margin-top: 20px; */
  font-family: Inter;
  text-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* width: 150px; */
}
.banner-title-mobile h1{
  font-weight: 700;
  font-size: 80px;
  text-shadow:#F7F7FA 0px -70px;
  letter-spacing: -8px;
}
.banner-text p{
  font-size: 20px;
  line-height: 220%;
  max-width: 450px;
}
.banner-picture-part{
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* start-hero */
.banner-image{
  /* margin-bottom: 50px; */
  height: 522px;
  width: 687px;
  /* animation: "fade 0.5s infinite"; */
  /* animation: fadeOut 1s forwards; */
  -webkit-animation: fadeInOutPause 6.2s linear forwards;
  animation: fadeInOutPause 6.2s linear forwards;
  opacity: 0;
}

@keyframes fadeInOutPause {
  0% { opacity: 0; }
  25% { opacity: 1; }
  50% { opacity: 1; }
  60% { opacity: 1; }
  80% { opacity: 0; }
  100% { opacity: 0; }
}

@-webkit-keyframes fadeInOutPause {
  0% { opacity: 0; }
  25% { opacity: 1; }
  50% { opacity: 1; }
  60% { opacity: 1; }
  80% { opacity: 0; }
  100% { opacity: 0; }
}

/* @keyframes fadeIn {
  0%,100% {opacity: 0;}
  50% {opacity: 1;}
  
} */

/* @keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
} */

.fadeOut {
  animation: fadeOut 30s forwards;
}

.fadeIn {
  animation: fadeIn 30s ease-in-out;
}
/* end- hero*/

.social-icons-section-mobile{
  width: 30px;
  color: #08133B;
  display: flex;
  flex-direction: column;
  align-items: center ;
  justify-content: center;
  margin: 15px;
  float: right;
}
.social-icons-section-mobile p{
  margin: 10px;
  float: right;
}

.social-icons-section{
  width: 30px;
  color: #08133B;
  display: flex;
  flex-direction: column;
  align-items: center ;
  justify-content: center;
  margin: 15px;
  float: right;
}
.social-icons-section p{
  margin: 10px;
  float: right;
}
.social-icon{
  height: 20px;
  width: 20px;
  margin: 10px;
}
.line-break {
  width: 30px;
	height: 1px;
	background-color: #08133B;
	content: '';
	/* z-index: 2; */
}
a[href="#getinvited"] {
  text-decoration: none;
}

.button-link{
  text-decoration: none;
}

.get-invited-button {
  border: 1px solid #08133B;
  background: #08133B;
  color: #FFFFFF;
  padding: 14px 25px;
  font-size: 32px;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}

.get-invited-button:hover {
  color: #08133B;
  background-color: #ACADCC;
}

.get-invited-arrow{
  color: #FFFFFF;
  width: 66px;
  height: 53px;
  margin-left: 30px;
  background-image: url("./assets/img/getinvitedarrow1.png");

}

.get-invited-button:hover .get-invited-arrow{
  background-image: url("./assets/img/getinvitedarrow.png");

}

/*******************ABOUT US CSS**************/
.about-us-container{
  width: auto;
  height: auto;
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 20px;
  color: #08133B;
}
.about-us-icon{
  width: 40px;
  height: 40px;
}
.mission-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 20%;
  margin: 40px;
  max-width: 400px;
  
}
.mission-container h2{
  font-family: WorkSans;
  font-weight: 700;
  margin: 20px;
  font-size: 32px;
  color: #08133B;
}

.mission-container p{
  text-align: center;
  font-weight: 400;
  font-family: WorkSans;
  line-height: 32px;
  color: #08133B;
}

@media screen and (max-width: 1000px){
  .about-us-container{
     flex-direction: column;
     align-items: center;
  }
}

/*************HOW IT WORKS CSS************/

.how-it-works-container{
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px;
  flex-direction: column;
}
.how-it-works-title h1{
  font-family: Inter;
  font-weight: 600;
  text-align: center;
  margin-bottom: 50px;

}
.how-it-works-container h1{
  color: #08133B;
  font-family: Inter;
  font-weight: 600;
}
.how-it-works-container p{
  color: #8A8A8A;
  font-family: Inter;
  font-weight: 400;
  margin: 10px;
}
.steps{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  vertical-align: top;
  flex-wrap: wrap;
  max-width: 1500px;
}
.steps-1-2 {
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  vertical-align: top;
}
/* .steps-3-4 {
  background-color: blue;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  vertical-align: top;
} */
.steps-5-6 {
  background-color: blue;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  vertical-align: top;
}
/* .step-container-3{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin: 50px;
} */
.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  /* margin: 50px; */
}


.step-container p{
  text-align: center;
  color: #8A8A8A;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  max-width: 300px;
}

.fullscreen-arrow{
  width: 80px;
  height: 10px;
  margin: 10px;
}
.hidden-arrow{
  width: 100px;
  height: 10px;
  margin: 10px;
  display: none;
}
.mobile-arrow{
  width: 40px;
  height: 40px;
  margin: 10px;
  display: none;
}
/* .steps-1-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  vertical-align: top;
} */
/* .steps-4-6 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  vertical-align: top;
} */
/*
@media screen and (max-width: 1000px){
  .steps-1-3{
     flex-direction: column;
  }
  .steps-4-6{
    flex-direction: column;
  }
 .steps-arrow {
    display: block;
    transform: rotate(90deg);
  }
  .steps-arrow-1000 {
    display: block;
  }

} */

@media screen and (max-width: 1600px){
  /* .steps-1-3{
     flex-direction: column;
  }
  .steps-4-6{
    flex-direction: column;
  } */
  .steps-icon{
    height: 80px !important;
    width: 80px !important;
  }
  .how-it-works-container h1{
    font-size: 32px !important;
  }
  /* .step-container{
    margin: 10px !important;
  } */

/* 
  .fullscreen-arrow{
    display: none !important;
  } */



  .hidden-arrow{
    display: block;
  }

  .mobile-arrow{
    display: none;
  }
 /* .steps-arrow {
    display: block;
    transform: rotate(90deg);
  } */
  /* .steps-arrow-1000 {
    display: block;
  } */

}

@media screen and (max-width: 899px){
  .fullscreen-arrow{
    display: none !important;
  }
  .hidden-arrow{
    display: none;
  }
  .mobile-arrow{
    display: block;
  }
}

@media screen and (max-width: 500px){
  .step-container h2{
    color: #08133B;
    font-family: Inter;
    font-weight: 600;
    font-size: 16px !important;
    margin-top: 30px;
    text-align: center;
    max-width: 300px;
  }
  .step-container p{
    text-align: center;
    color: #8A8A8A;
    font-family: Inter;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 160%;
    max-width: 300px;
  }
}



/* .step-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin: 50px;
  
} */
.step-container h2{
  color: #08133B;
  font-family: Inter;
  font-weight: 600;
  font-size: 24px;
  margin-top: 30px;
  text-align: center;
  max-width: 300px;
}
.step-container p{
  text-align: center;
  color: #8A8A8A;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  max-width: 300px;
}
.steps-icon{
  height: 150px;
  width: 154px;
}
.steps-arrow{
  width: 100px;
  height: 10px;
  margin: 10px;
}
.steps-arrow-1000{
  width: 100px;
  height: 10px;
  margin:10px;
  transform: rotate(90deg);
  /* display: hidden; */
}

/******************CATEGORIES CSS*********/
categories-container{
  height: 450px !important;
}
.categories-title{
  margin: 10px;
  /* text-align: center; */

}
.categories-title h1{
  font-family: Inter;
  font-weight: 600;
  /* font-size: 48px; */
}
.carousel-container{
  padding-bottom: 10px;
}
.custom-dot-list-style{

}


.carousel-card {
  height: auto;
  width: auto;
  margin-bottom: 10px;
  max-width:630px;
  max-height:400px;
  background-color: #FFFFFF;
  border-radius: 20px;
  margin: 10px;
  
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

}

/******************DOWNLOAD CSS*************/
.download-background{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-image: url("./assets/img/logodownloadbackground.png");
  background-repeat: repeat;
  padding: 50px;
}
.download-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: auto;
}
.join-waitlist-button {
  border: 2px solid #08133B;
  /* background: #FFFFFF; */
  color: #08133B;
  padding: 13px 20px;
  font-size: 24px;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  font-family: WorkSans;
  font-weight: 400;
  margin: 10px;

}
.join-waitlist-button:hover {
  background: lightgrey;
}

@media screen and (max-width: 1100px){
  .download-container{
     flex-direction: column;
  }
  .download-images{
    display: none !important;
  }
  .download-images-mobile{
    display: block !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row !important;
  }
}
@media screen and (max-width: 600px){
  .download-text h1{
    font-size: 32px !important;
  }
  .download-text h2{
    font-size: 60px !important;
  }
  .download-text h3{
    font-size: 32px !important;
  }
  .download-image-android{
    /* height: 80%; */
    width: 160px;
  }
  .download-image-apple{
    /* height: 80%; */
    width: 150px;
  }
  .iphone-image{
    height: 350px;
    width: 350px;
  }
  .download-images-mobile{
    flex-direction: row !important;
  }

}
.download-text{
  height: auto;
  margin: 10px;
  max-width: 700px ;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.download-text h1{
  font-family: WorkSans;
  font-size: 6vw;
  font-weight: 800;
  letter-spacing: 6%;
  text-align: left;
}
.download-text h2{
  font-family: WorkSans;
  font-size: 5vw;
  font-weight: 800;
  letter-spacing: 6%;
  text-align: left;

}
.download-text h3{
  font-family: WorkSans;
  font-size: 4vw;
  font-weight: 400;
  letter-spacing: 6%;
  text-wrap:wrap;
}
.download-images{
  display: flex;
  /* flex-wrap: wrap; */
  /* flex-direction: row; */
  height: auto;
  width: auto;
  text-align: left;
  /* justify-content: space-between; */
}
.download-images-mobile{
  display: none;

}
.download-image-apple{
  max-height: 86px;
  max-width: 260px;
  margin: 10px 10px 10px 0px;

}
.download-image-android{
  max-height: 86px;
  max-width: 292px;
  margin: 10px 10px 10px 0px;

  /* height: auto;
  width: auto; */
  /* margin: 10px; */
}
.iphone-container{
  height: auto;
  width: auto;
}
/*****************FOOTER CSS**************/
.footer-container{
  background-color: #08133B;
  display: flex;
  color: #FFFFFF;
  font-family: WorkSans;
  /* align-items: flex-start; */
  padding: 30px;
  flex-direction: column;
}
.footer-container a{
  color: #FFFFFF;
  text-decoration: none !important;
}
.footer-container p{
  color: #FFFFFF;
  margin-top: 15px;
}
.newsletter-section{
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.newsletter-section h4{
  color: #ACADCC;
  font-weight: 600;
}
.newsletter-section h3{
  font-weight: 700;
  color: #FFFFFF;
}
.newsletter-section p{
  color: #ACADCC;
}
.newsletter-input input{
  background-color: transparent;
  max-width: 400px;
  height: 50px;
  border: 1px solid #ACADCC;
  border-radius: 10px;
  padding: 10px;
  color: #ACADCC;
}
.newsletter-input button{
  width: 110px;
  height: 50px;
  border: 1px solid #ACADCC;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  background-color: #ACADCC;
  color: #08133B;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}

.footer-sections{
  display: flex;
  /* align-items: center; */
  justify-content:space-between !important;
  align-items: flex-start;
  line-height: 350%;
  flex-wrap: wrap;
}
.sections-1-2{
  display: flex;
  /* align-items: center; */
  justify-content: space-evenly !important;
  flex-direction: row;
  align-items: flex-start;
  line-height: 350%;
  width: auto;
}
.sections-3-4{
  display: flex;
  /* align-items: center; */
  flex-direction: row;
  align-items: flex-start;
  line-height: 350%;
  width: auto;
}
.section{
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  line-height: 350%;
  margin: 10px;
}
.follow-section{
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  line-height: 350%;
  margin: 10px;
}
.follow-section h1{
  font-size: 16px;
  font-weight: 600;
}

.section h1{
  font-size: 16px;
  font-weight: 600;
}
.footer-icon{
  height: 18px;
  width: 18px;
  margin: 5px;

}
.copyright-container{
  background-color: #08133B;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 2px solid #9CA1B1;
}
.payment{
  width: 50px;
  height:auto;
  margin:10px;
}
.copyright-section p{
  font-family: WorkSans;
  font-size: 14px;
  color: #9CA1B1 ;
}
.mobile-social-section{
  display: none;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
}
.mobile-social-section h1{
  color: #FFFFFF;
  font-family: WorkSans;
  font-size: 16px;
}
.footer-social-icons{
  display: flex;
  flex-direction: row;
}
.first-footer-icon{
  height: 18px;
  width: 18px;
  margin-top: 5px;
  margin-right: 5px;
}

@media screen and (max-width: 600px){
  .follow-section{
    display: none;
  }
  .mobile-social-section{
    display: flex;
  }
}

/*****************MODAL CSS**************/
.modal-button{
  color: #FFFFFF;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;

}
/*****************GET INVITED CSS**************/
.get-invited-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #FFFFFF;
  color: #08133B;
  font-family: WorkSans;
  margin: 150px auto;

}
.get-invited-text{
  text-align: center;
}
.get-invited-text h1{
  font-size: 48px;
  font-weight: 600;
  margin: 20px;
}
.get-invited-text p{
  font-size: 24px;
  font-weight: 400;
  line-height: 160%;
  margin: 10px;
}
.get-invited-form{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin: 20px; */
  max-width: 890px;
}
.get-invited-form form{
  width:auto;
}
.get-invited-form input{
  width: 400px;
  height: 50px;
  border: 1px solid #9CA1B1;
  border-radius: 10px;
  margin: 20px;
  padding: 10px;
}
.get-invited-form select{
  width: 400px;
  height: 50px;
  border: 1px solid #9CA1B1;
  border-radius: 10px;
  margin: 20px;
  padding: 10px;
}

.get-invited-form button{
  width: 400px;
  height: 50px;
  border: 1px solid #9CA1B1;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  background-color: #08133B;
  color: #FFFFFF;
  font-family: WorkSans;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}
.get-invited-form button:hover{
  background-color: #FFFFFF;
  color: #08133B;
}
.account-type{
  width: 400px;
  height: 50px;
  border: 1px solid #9CA1B1;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;

}
/***************PARTNERSHIPS CSS**************/
.partnerships-background{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  margin-bottom: 30px;
}
@media screen and (max-width: 1220px){
  .partnerships-container{
     width: 100% !important;
     border-radius: 0%;
  }
  .partnerships-container::before,
  .partnerships-container::after {
    display: none;
  }

}
.partnerships-title h1{
  font-family: Inter;
  font-weight: 600;
  color: #08133B;
  margin: 10px;
  /* text-align: center; */
}
.partnerships-container{
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color:#F7F7FA ;
  overflow: hidden;
  padding: 10px 0;
  padding-bottom:10px;
  white-space: nowrap;
  width: 90%;
  position: relative;
}
.partnerships-container::before, .partnerships-container::after{
  position:absolute;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background: linear-gradient(to right, rgba(255,255,255,0), #FFFFFF);
  content: "";
  z-index: 2;
}
.partnerships-container::before{
  position:absolute;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background: linear-gradient(to left, rgba(255,255,255,0), #FFFFFF);
  content: "";
  z-index: 2;
}
.partnerships-container:hover .partnerships-carousel{
  animation-play-state: paused;

}
.partnerships-carousel{
  display: inline-block ;
  animation: 60s slide infinite linear;
}
@keyframes slide{
  from {
    transform: translateX(0);
  }
  to{
    transform: translateX(-100%);
  }
}
.partnerships-image{
  height: 80px;
  width: auto;
  margin: 60px;
}
/**************LOGIN CSS**************/
.login-container{
  background-image: url("./assets/img/logodownloadbackground.png");
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #FFFFFF;
  color: #08133B;
  font-family: WorkSans;
  min-height: 100vh;
  min-width: 100vw;
}
.login-form{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  width: 40%;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 30px;
  background-color: #FFFFFF;
  font-family: WorkSans;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.login-form h1{
  font-size: 48px;
  font-weight: 600;
  margin: 20px;
}
.login-form input{
  width: 60%;
  height: 50px;
  border: 1px solid #9CA1B1;
  border-radius: 10px;
  margin: 20px;
  padding: 10px;
}
.login-form button{
  width: 100px;
  height: 50px;
  border: 1px solid #9CA1B1;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  background-color: #08133B;
  color: #FFFFFF;
  font-family: WorkSans;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}
@media screen and (max-width: 1000px){
  .login-form{
    width: 100%;
  }
  
}
/**************REGULATIONS CSS**************/
.regulation-container{
  background-image: url("./assets/img/logodownloadbackground.png");
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  background-color: #FFFFFF;
  color: #08133B;
  font-family: WorkSans;
  min-height: 100vh;
  min-width: 100vw;

}
.regulation-text-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 200px;
  width: 60%;
  padding: 40px;
  border-radius: 30px;
  background-color: #FFFFFF;
  font-family: WorkSans;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 1000px){
  .regulation-text-container{
    width: 90%;
  }
}
/*************ADMIN CSS**************/
.admin-container{
  background-image: url("./assets/img/logodownloadbackground.png");
  font-family: WorkSans;
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #FFFFFF !important;
  color: #08133B !important;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.invite-section{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 80%;
}
.pending-invites{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  width: 25%;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 30px;
  background-color: #FFFFFF;
  font-family: WorkSans;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor:pointer;

}

.pending-invites:hover {
  float: left;
}
.accepted-invites{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  width: 25%;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 30px;
  background-color: #FFFFFF;
  font-family: WorkSans;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor:pointer;

}
.rejected-invites{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  width: 25%;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 30px;
  background-color: #FFFFFF;
  font-family: WorkSans;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor:pointer;

}







.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #08133B;
  color: #FFFFFF;
  padding: 20px;
  position: fixed;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin-bottom: 10px;
}

.sidebar a {
  text-decoration: none;
  color: #FFFFFF;
}

.sidebar a:hover {
  color: #ACADCC;
}

.sidebar .active {
  font-weight: bold;
}

/*************PENDING INVITES PAGE CSS**************/
.pending-invites-container{
  background-image: url("./assets/img/logodownloadbackground.png");
  font-family: WorkSans;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #FFFFFF !important;
  color: #08133B !important;
  min-height: 100vh;
  min-width: 100vw; 
}
.pending-invites-list{
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 80%;
  padding-bottom: 30px;
  border-radius: 30px;
  background-color: #FFFFFF;
  font-family: WorkSans;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.pending-title{
  display: flex;
  justify-content: center;
  font-size: 32px;
  font-weight: 600;
  margin: 20px;
  border-bottom: 1px solid #ACADCC;
}
.pending-invite{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #ACADCC;
}
.pending-accept-button{
  background-color: green;
  color: #FFFFFF;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px;
}
.pending-reject-button{
  background-color: red;
  color: #FFFFFF;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px;
}
.back-button{
  background-color: #08133B;
  color: #FFFFFF;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px;
  width: 100px;
}